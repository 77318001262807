import axios from "axios";

const server = axios.create({
  // baseURL: "http://localhost:5000",
  // baseURL: "https://pakagus-server.onrender.com/",
  baseURL: "https://pakagus-server.tec.my.id",
  timeout: 100000,
});

server.interceptors.request.use(function (request) {
  return request;
});

server.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err);
    if (err.response) {
      if (err.response.status === 400) {
        localStorage.removeItem("token");
        window.location.href = window.location.origin;
      } else if (err.response.status === 401) {
        localStorage.setItem("err-login", err.response.data.message);
      }
    } else {
      alert("Error: ada kesalahan");
    }
  }
);

export default server;
